//demo styles
table#example{
    padding: 0rem 0 2rem 0;
}
table.dataTable{
    font-size: 0.875rem;
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}



table.dataTable thead th, table.dataTable thead td{
    border-bottom: 0.125rem solid $border-color;
    border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td{
    background: transparent;
}


table.dataTable thead th{
    color: $headings-color;
	white-space:nowrap;
	font-size:1rem;
	text-transform:capitalize;
    font-weight: 500;
	padding: 1.25rem 0.9375rem;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
	@include respond ('laptop'){
		font-size:1rem;
	}
}

table.dataTable tbody td{
    padding: 1.25rem 0.9375rem;
	font-size: 15px;
	font-weight: 400;
	border-bottom:0;
	@include respond('phone') {
		padding: 0.5rem 0.3125rem;
	}
	@include respond ('laptop'){
		font-size:0.875rem;
		padding:0.5rem 0.9375rem;
	}
}


table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
td.text-ov {
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate{
	align-items: center;
    display: flex;
	flex-flow:wrap;
	.paginate_button.previous,.paginate_button.next{
		font-size: 16px;
		height:40px;
		width: 71px;
		background:transparent;
		border-radius:$radius;
		padding: 0 0.45rem;
		line-height:40px;
		border:1px solid var(--primary);
		margin:0 0.625rem;
		display:inline-block;
		color:var(--primary)!important;
		box-shadow:none!important;		
		&.current:hover,
		&.previous:hover,
		&.next:hover{
			color:var(--primary)!important;
			background:var(--rgba-primary-1)!important;
			border-color: transparent;

		}
		&.disabled{
			color:var(--primary)!important;
		}
		@include respond ('phone'){
			height: 2.125rem;
			width: fit-content;
			line-height: 2.125rem;
			padding: 0 10px;
			font-size: 14px;
		}
	}
	span{
		.paginate_button{
			height:40px;
			width:40px;
			padding:0;
			margin-left:0rem;
			margin:0 0.125rem;
			line-height:40px;
			text-align:center;
			font-size:16px;
			border-radius:$radius;
			border:0!important;
			color:var(--primary)!important;
			cursor: pointer;
			box-shadow:none!important;
			
			&.current{
				color: $white!important;
				background:var(--primary)!important;
				&:hover{
					color: $white!important;
					background:var(--primary)!important;
				}
			}
			&:hover,&:active{
				color:var(--primary)!important;
				background:var(--rgba-primary-1)!important;
			}
			@include respond ('phone'){
				height:30px;
				width:30px;
				line-height:30px;
			}
		}
	}
}

.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 0.0625rem solid #e2e2e2;
        padding: .3rem 0.5rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 0.3125rem;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 0.9375rem;
		.bootstrap-select{
			width: 5rem;
			margin: 0 0.3125rem;
			width:70px!important;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:1.875rem;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}

.dataTablesCard{
	background-color:$white;
	border-radius:$radius;
	&.border-no{
		td{
			border-top:0!important;
		}
	}
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
	color: $body-color !important;
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter, 
.dataTables_wrapper .dataTables_processing, 
.dataTables_wrapper .dataTables_paginate {
    color: $body-color;
	border-radius:1rem;
	padding:0.125rem 0;
	margin-bottom:0.35rem;
}
.paging_simple_numbers.dataTables_paginate {
	padding: 0.6125rem 0.3125rem;
	@include respond ('phone'){
		padding: 0.8125rem 0.3125rem;
	}
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
	color: $body-color !important;
}



table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}

.dataTables_wrapper{
	.dataTables_length{
		.bootstrap-select{
			.dropdown-toggle{
				font-size: 0.813rem !important;
				padding: 0.625rem 1rem;
			}
		}
	}
}
table.tb-transaction tbody td {
		padding: 0.75rem 1.875rem;
}

table.tickettable tbody td {
		padding: 1.25rem 1.875rem;
		color:var(--secondary);
}
table.tickettable thead th {
		font-size:1rem! important;
		padding: 1.65rem 1.875rem;
		color: var(--secondary);
		border-bottom:1px solid #DBDBDB;
}
.table.tickettable.table-striped tbody tr:first-child:hover {
    background-color: transparent;
}
.table.tickettable.table-hover thead tr:first-child:hover {
    background-color: transparent;
}
.table.table-sell.table-hover thead tr:first-child:hover {
    background-color: transparent;
}
.table.table-sell.table-hover tbody tr:hover {
    background-color: var(--secondary);
}

.orderbookTable{
	thead tr th{
		font-size:1rem;
		border:0;
	}
	tbody tr td{
		border:0;
		padding:0.7375rem 0.625rem;
	}
}
.dataTabletrade{
	.dataTables_wrapper{
		.dataTables_length{
			display:none;
		}
		.dataTables_filter{
			display:none;
		}
		#example{
			padding: 0rem 0 0.5rem 0;
		}
		.dataTable {
			thead tr th{
				font-size: 1rem;
				border-bottom:1px solid #eee;
			}
			tbody tr td{
				font-size:0.9rem;
				padding:1.05rem 0.9375rem;
			}
		}
		.dataTables_paginate{
			margin-bottom:0;
		}
		.sorting_asc {
			background-image: unset;
		}
		.sorting {
			background-image: unset;
		}
	}
}
.dataTablehistory{
	.dataTables_wrapper{
		.dataTable {
			thead tr th{
				font-size: 1rem;
			}
			tbody tr td{
				font-size:0.8rem;
				padding:1.05rem 0.9375rem;
			}
		}
		.dataTables_length{
			display:none;
		}
		.dataTables_filter{
			display:none;
		}
		table#example {
			padding: 0rem 0 0.6rem 0;
		}
		.sorting_asc {
			background-image: unset;
		}
		.sorting {
			background-image: unset;
		}
	}
}
.dataTablemarket{
	.dataTables_wrapper{
		.dataTables_length{
			display:none;
		}
		.dataTables_filter{
			margin-bottom:0;
			margin-top:0;
			float:right;
			display:none;
		}
		#example{
			padding: 0rem 0 0.5rem 0;
		}
		.dataTable {
			thead tr th{
				font-size: 0.9rem;
				padding: 0.75rem 0.6375rem;
				border-bottom:1px solid #eee;
			}
			tbody tr td{
				font-size:1rem;
				padding:1.05rem 0.9375rem;
				color:var(--secondary);
				font-weight:400;
				@at-root [data-theme-version="dark"] & {
					color:$white;
				}
				a{
					font-size:1rem;
					font-size:400;
					color:var(--secondary);
				}
			}
		}
		.dataTables_paginate{
			margin-bottom:0;
		}
		.sorting_asc {
			background-image: unset;
		}
		.sorting {
			background-image: unset; 
		}
	}
}
.table.dataTable thead .sorting_desc{
	background-image: unset;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after{
	content:"";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    background-image: none;
    margin-left: 10px;
    font-size: calc(100% - 4px);
    opacity: 0.5;
}
table.dataTable thead .sorting:after {//both
    content:"\f0dc";
}
table.dataTable thead .sorting_asc:after {//top
    content:"\f0de";
	opacity: 1;
}
table.dataTable thead .sorting_desc:after {//bottom
    content:"\f0dd";
	opacity: 1;
}
.order-tbl{
	td{
		padding: 0.5375rem 0.625rem;
	}
}
table.dataTable thead .sorting {
    background-image: none;
}
table.dataTable thead .sorting_desc {
    background-image: none;
}
table.dataTable thead .sorting_asc {
    background-image: none;
}
#example2_wrapper #example2_filter {
    float: left;
}

.dataTables_scrollBody thead {
    display: none;
}
#example5_wrapper table.dataTable thead .sorting_asc {
 .custom-checkbox .form-check-input:checked{
	border:2px solid $white;
	}
}
.tbl-report{
	thead{
		tr th{
			font-weight:600;
			font-size:1rem;
			color:#374557;
		}
	}
}
/* .table-responsive{
	.dataTables_filter{
		float: right;
        margin-top: -3.313rem;
	}
} */
/* #example_wrapper,
#example2_wrapper,
#example3_wrapper,
#example4_wrapper{
	.dataTables_paginate span .paginate_button{
		padding: 2.8px 9.6px;
	}
} */
/* .dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid $border !important;
        padding: .3rem 0.5rem;
		//background-color:var(--rgba-primary-5);
        //color: $main-color;
        border-radius: $radius  !important;
		margin-left: 14px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border!important;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 70px!important;
			margin: 0 5px;

			.dropdown-toggle::after{
				border-top: 0.4em solid;
				border-right: 0.4em solid transparent;
				border-left: 0.4em solid transparent;
			}
		}
	}
} */
/*  .paging_simple_numbers.dataTables_paginate{
	border: none;
    float: right;
	//margin-top:-1.938rem;

} */
#example3{
	.sorting_asc{
		&:after{
			content:""!important;
		}
		
	}
	.sorting_desc:after{
		content:""!important;
	}
}
