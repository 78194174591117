.gallery-icon {
  position: absolute;
  margin-left: 5%;
  font-size: 40px;
  color: blue;
  padding: 10px;
  cursor: pointer;
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: blue;
  opacity: 0;
  transition: 0.2s all ease-out;
}

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.6;
  transition: 0.2s all ease-out;
}

.de-image-hover img {
  transform: scale(1);
  transition: 0.5s all ease-out;
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.big-file-uploader {
  font-size: 4.5em;
  /* Increase the font size */
  padding: 80px;
  /* Increase the padding */
}

.gallery-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 250px;
}

.element-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

.employee-image {
  object-fit: cover;
  object-position: center;
  width: 400px;
  height: 400px;
  border-radius: 100%;
}

@media (max-width: 992px) {
  .employee-image {
    width: 300px;
    height: 300px;
  }
}

.unread-messages {
  background-color: #ff8d0e;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.margin-start {
  left: 0.5rem !important;
}

.custom-modal-size .modal-dialog {
  max-width: 90% !important;
  /* or any custom width */
}

.selected-mark {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  z-index: 999;
  padding: 10px;
  cursor: pointer;
}

.custom-favBtn {
  background-color: white;
  border: 2px solid #ff8d0e;
  color: black;
}

.custom-favBtn:hover {
  background-color: #FF8D0E;
  color: white;

}

.trending-img-delete {
  top: 0;
  right: 10px;
  padding: 10px;
}

.trending-custom-button{
  bottom: 0;
  margin-bottom: 5px;
  margin-left: 5px;
}

.logo-image {
  display: block;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 5px;
  height: 60px;
  object-fit: cover;
  object-position: center;
}


.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  /* background-color: #ff8d0e; */
  cursor: pointer;
}

.color-box-discovery {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.working-hours {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ff8d0e;
  padding: 5px;
}

.working-hours-input {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ff8d0e;
  padding: 5px;
}
@media (max-width: 768px) {
  .working-hours-input {
    width: 65px;
  }
}

.calender-style {
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
  color: black;
  font-size: 14px;
}

.custom-pointer-cursor {
  cursor: pointer !important;
}

.chart-text {
  top: 53%;
  left: 41%;
  transform: translate(-50%, -50%);
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .chart-text {
    left: 50%;
  }
}

/* Media query for tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .chart-text {
    left: 43%;
  }
}

/* Media query for laptops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .chart-text {
    left: 55%;
  }
}


@media only screen and (max-width: 767px) {
  .apexcharts-legend {
    display: none!important;
  }
}

.chart-widht {
  width: 300px;
}

/* --------- inventory card -------- */

.card-image-custom {
  object-fit: cover;
  object-position: center;
  height: 270px;
}

.card-body-custom {
  height: 220px;
}

.main-card-custom {
  width: 18rem;

  @media only screen and (max-width: 767px) {
    width: 25rem;
  }
}

/* ---------- trending cards ---------- */
.buttons-custom {
  display: flex;
  justify-content: space-between !important;
}

/* ---------- Visit ---------- */
.visit-employee{
  padding: 2px 10px;
  background-color: gainsboro;
  border-radius: 20px;
}
.visit-employee::before{
  content: "👤";
  border-radius: 20px;
  /* background-color: #ff8d0e; */
}

.visit-service::before{
  content: "✂️";
  border-radius: 20px;
  /* background-color: #ff8d0e; */
}

/* ---------- trending cards ---------- */
.avatar-circle {
  width: 35px;
  height: 35px;
  background-color: blue;
  color: white;
  border-radius: 50%;
}

.zIndex {
  z-index: 999 !important;
}
.zIndexH {
  z-index: 1000 !important;
}


/* BookingOffCanvas.css */

.time-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.time-slot {
  flex-basis: calc(25% - 10px); /* 4 in a row, with 10px gap */
  border: 1px solid #000;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
}

.time-slot.selected {
  background-color: #ccc; /* Change this to your preferred color */
}


/* ---------- Opening Hours ---------- */
.opening-hours-select{
  width: 120px;
}

/*  color field of the add product */
.color-span {
  display: inline-block;
  margin-right: 8px;
}

.color-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 4px;
  vertical-align: middle;
}

/* List image */
.list-images{
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 768px) {
  .list-images{
    width: 100px;
    height: 100px;
  }
  
}

.list-border{
  border-bottom: 1px solid rgb(224, 224, 224);
}

.list-border:last-child{
  margin-bottom: 0;
  border: none;
}
.associated-image{
  width: 300px;
  object-fit: cover;
  object-position: center;
}

.orderDetails-image{
  width: 100px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}

.active-btn{
  background-color: #ff8d0e;
  color: white;
}

.quantity-input{
  width: 50px;
  height: 30px;
  border: none;
  margin-left: 20px;
  padding: 5px;
}

.disabled-form{
  pointer-events: none;
  opacity: 0.5;
}